import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/page-objects/Layout";
import Header from "../components/page-objects/Header";
import Spacer from "../components/ui-objects/Spacer";

const ContentPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout
      description={post.frontmatter.seoinfo.description !== null ? post.frontmatter.seoinfo.description : ''}
      title={post.frontmatter.seoinfo.seotitle !== null ? post.frontmatter.seoinfo.seotitle : ''}
      keywords={post.frontmatter.seoinfo.keywords !== null ? post.frontmatter.seoinfo.keywords : ''}
    >
      <Header
        headerImage={post.frontmatter.headerimagewrap.featuredimage.childImageSharp.fixed.srcWebp}
        headerImageAlttext={post.frontmatter.headerimagewrap.alttextheaderimage}
        promoblockVisible={post.frontmatter.headerpromo.headerpromovisible}
        promoblockContent={post.frontmatter.headerpromo.headerpromocontent}
        promoblockButtonVisible={post.frontmatter.headerpromo.headerpromobuttonvisible}
        promoblockButtonText={post.frontmatter.headerpromo.headerpromobuttonlabel}
        promoblockButtonUrl={post.frontmatter.headerpromo.headerpromobuttonurl}
        fullBackgroundImage={post.frontmatter.headerimagewrap.fullbgimage}
      />
      <section>
        <section className="contentpage">
          <div className="">
            <Spacer size="xxl" />
            <h1>{post.frontmatter.title}</h1>
            <Spacer size="l" />
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <Spacer size="xxl" />
          </div>
        </section>
      </section>
    </Layout>
  );
};

ContentPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ContentPage;

export const pageQuery = graphql`
          query ContentPageID($id: String!) {
            markdownRemark(id: { eq: $id }) {
              id
              html
              frontmatter {
                headerimagewrap {
                  featuredimage {
                    childImageSharp {
                      fixed(webpQuality: 90, width: 1600) {
                        srcWebp
                      }
                    }
                  }
                  alttextheaderimage
                  fullbgimage
                }
                headerpromo {
                  headerpromobuttonlabel
                  headerpromobuttonurl
                  headerpromobuttonvisible
                  headerpromocontent
                  headerpromovisible
                }
                date(formatString: "MMMM DD, YYYY")
                title
                description
                tags
                seoinfo {
                  description
                  seotitle
                  keywords
                }
              }
            }
          }
        `;
